import { createRoot } from 'react-dom/client';
// import { lazy } from 'react';
import { Suspense, StrictMode, lazy } from 'react';
import { KcPage, type KcContext } from './keycloak-theme/kc.gen';
// import { getKcContextMock } from "./keycloak-theme/login/KcPageStory";

const App = lazy(() => import("app/App"));

// The following blocks of code can be uncommented to test a specific keycloak page with `npm keycloakify start-keycloak`
// In the future when all customers are migrated, we will be uncommenting this code and using the oidc-spa provider for authentication
// documentation for keycloakify and oidc-spa can be found @ https://docs.keycloakify.dev/
// Don't forget to comment back or your bundle size will increase

// if (process.env.NODE_ENV === "development") {
//     window.kcContext = getKcContextMock({
//         pageId: "login-update-password.ftl",
//         overrides: {}
//     });
// }


createRoot(document.getElementById('root')!).render(
  <StrictMode>
    {window.kcContext ? (
      <KcPage kcContext={window.kcContext} />
    ) : (
      <Suspense>
        <App />
      </Suspense>
    )}
  </StrictMode>
);
declare global {
  interface Window {
    kcContext?: KcContext;
  }
}
